import React from 'react';
import {navigate} from 'gatsby-link';
import {type, space} from '@workday/canvas-kit-react/tokens';
import {Box, Flex} from '@workday/canvas-kit-react/layout';
import {Graphic} from '@workday/canvas-kit-react/icon';
import {emptyStateSearchGraphic} from '@workday/canvas-graphics-web';
import SEO from '../components/SEO';
import {ContentBody} from '../components/content/ContentBody';
import {FAQNoticeCard} from '../components/content/NoticeCard';
import {H1} from '../components/StyledElements';
import {SearchResult} from '../components/search/SearchResult';
import {getQueryValue, getSearchResults} from '../utils/search';

const Page = () => {
  const query = getQueryValue('q');
  if (!query) {
    if (typeof window !== 'undefined') {
      navigate('/'); // Of course Gatsby's `navigate` doesn't check for SSR >:(
    }
    return null;
  }
  const results = getSearchResults(query);

  if (results.length) {
    return (
      <ContentBody>
        <SEO title={`Search: ${query}`} />
        <H1>{`Results for “${query}”`}</H1>

        <Box as="ul" listStyle="none">
          {results.map(({title, path, description}) => (
            <SearchResult title={title} path={path} description={description} key={path} />
          ))}
        </Box>

        <FAQNoticeCard />
      </ContentBody>
    );
  }

  return (
    <ContentBody>
      <SEO title={`Search: ${query}`} />
      <Flex gap="m" alignItems="center" flexDirection="column">
        <Graphic src={emptyStateSearchGraphic} css={{margin: `${space.xxl} 0 ${space.s}`}} />
        <H1 css={type.levels.heading.medium}>{`No results were found for “${query}”`}</H1>
      </Flex>
      <FAQNoticeCard />
    </ContentBody>
  );
};

Page.title = 'Search';

export default Page;
