import React from 'react';
import {withPrefix} from 'gatsby';
import {type, colors, space, borderRadius} from '@workday/canvas-kit-react/tokens';
import {SystemIcon} from '@workday/canvas-kit-react/icon';
import {chevronRightSmallIcon} from '@workday/canvas-system-icons-web';
import {Box} from '@workday/canvas-kit-react/layout';
import {Link} from '../content/Link';
import {kebabToTitleCase} from '../../utils/strings';
import {SearchItem} from '../../utils/search';
import {getCategoryMeta} from '../../utils/getCategoryMeta';

export const SearchResult = ({title, path, description}: Omit<SearchItem, 'id'>) => {
  const breadcrumb = path.split('/').slice(1, -2);
  const categoryIcon = getCategoryMeta(breadcrumb[0])?.icon;

  return (
    <Box as="li" marginBottom="xs">
      <Link
        href={withPrefix(path)}
        css={{
          textDecoration: 'none',
          display: 'block',
          margin: `0 -${space.xs}`,
          padding: space.xs,
          borderRadius: borderRadius.m,
          '&:focus': {boxShadow: 'none', background: colors.soap200},
        }}
      >
        <h2
          css={{
            ...type.levels.body.large,
            color: undefined,
            fontWeight: type.properties.fontWeights.bold,
            textDecoration: 'underline',
            marginBottom: space.xxs,
          }}
        >
          {categoryIcon && (
            <SystemIcon
              color={colors.blueberry400}
              colorHover={colors.blueberry400}
              icon={categoryIcon}
              css={{margin: '-4px 16px -4px 0'}}
            />
          )}
          {title}
        </h2>
        {description && (
          <p
            css={{
              ...type.levels.body.small,
              color: colors.blackPepper300,
              marginBottom: space.xxs,
              paddingLeft: space.xl,
            }}
          >
            {description}
          </p>
        )}
        <p css={{...type.levels.subtext.medium, ...type.variants.hint, paddingLeft: space.xl}}>
          in{' '}
          {breadcrumb.map((parent, index) => {
            return (
              <>
                {kebabToTitleCase(parent)}
                {index < breadcrumb.length - 1 && (
                  <SystemIcon
                    color={colors.licorice300}
                    colorHover={colors.licorice300}
                    size={20}
                    css={{margin: '-6px 2px'}}
                    icon={chevronRightSmallIcon}
                  />
                )}
              </>
            );
          })}
        </p>
      </Link>
    </Box>
  );
};
